import { defineStore } from 'pinia'

interface SessionPrepRootState {
  // States
  working: boolean
  // State
  readyToStart: boolean
  // individualMode: boolean
  activeNode: NodePanel
  helpPanel: boolean
  session: {
    type: SessionType | ''
    focus: SessionFocus | ''
    individual: {
      name: string
      relation: string
    }
  }
  nodes: CreateNodes
}

export const useSessionPrepStore = defineStore({
  id: 'sessionprepstore',

  state: (): SessionPrepRootState => ({
    // States
    working: false,
    readyToStart: false,
    // State
    // individualMode: false,
    activeNode: 'focus',
    helpPanel: false,
    session: {
      type: '',
      focus: '',
      individual: {
        name: '',
        relation: '',
      },
    },

    nodes: {
      focus: {
        label: 'Focus',
        complete: false,
        available: true,
        valid: true,
      },
      type: {
        label: 'Type',
        complete: false,
        available: false,
        valid: false,
      },
      individual: {
        label: 'Individual',
        complete: false,
        available: false,
        valid: false,
      },
      summary: {
        label: 'Summary',
        complete: true,
        available: false,
        valid: false,
      },
    },

  }),

  actions: {
    updateSessionType(type: SessionType) {
      this.session.type = type
    },
    updateSessionFocus(focus: SessionFocus) {
      this.session.focus = focus
    },
    processContinue() {
      if (this.panelRequirementsSatisfied) {
        const valid = this.nodes[this.activeNode].valid

        switch (this.activeNode) {
          case 'focus':
            if (valid) {
              // if (this.session.focus === 'other')
              // this.nodes.individual.available = true

              this.activeNode = 'type'
            }
            break

          case 'type':
            if (valid) {
              if (this.session.focus === 'other') {
                this.nodes.individual.available = true
                this.activeNode = 'individual'
              }

              else { this.activeNode = 'summary' }
            }
            else {
              alert('Criteria not fulfulled')
            }

            break

          case 'individual':
            if (valid) {
              this.nodes.summary.available = true
              this.activeNode = 'summary'
            }

            break

          default:
            break
        }
      }
      else {
        // alert('Criteria not fulfulled')
      }
    },

    processBack() {
      switch (this.activeNode) {
        case 'type':
          this.activeNode = 'focus'

          this.session.type = ''

          break

        case 'individual':
          this.activeNode = 'type'

          this.session.individual.name = ''
          this.session.individual.relation = ''
          break

        default:
          break
      }
    },

    // This function represents pushing back or manually selecting a previous node
    selectNode(node: NodePanel) {
      // Still need to see if this is required
    },

  },

  getters: {
    panelRequirementsSatisfied: (state) => {
      switch (state.activeNode) {
        case 'type':
          return state.session.type !== ''

        case 'focus':
          return state.session.focus !== ''

        case 'individual':
          return state.session.individual.name && state.session.individual.name.length >= 3

        default:
          return false
          break
      }
    },
    individualMode: state => state.session.focus === 'other',
    fieldsSet: (state) => {
      const fields = state.session
      switch (state.activeNode) {
        case 'type': // Type
          return !!fields.type

        case 'focus': // Focus
          return !!fields.focus

        case 'individual': // Individual

          return (
            !!fields.type
                && !!fields.focus
                && !!fields.individual.name
          )

        case 'summary': // Summary

          if (fields.focus && fields.focus === 'other') {
            return (
              !!fields.type
                  && !!fields.individual.name
            )
          }
          else if (fields.focus && fields.focus === 'self') {
            return (
              !!fields.type
            )
          }
          else {
            return false
          }

        default:
          return false
      }
    },
  },
})
